<template>
  <div data-app>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="12" md="12" style="color: #e33354" class="ml-4">
            <h3>{{ cardTitle }}</h3>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="6"
            class="mt-1 text--secondary subtitle-2"
          >
            <div>
              <span>CSV exportálás</span>
            </div>
            <date-range-picker
              v-model="dateRange"
              :date-format="dateFormat"
              :locale-data="localeData"
              opens="right"
              :ranges="false"
              @update="exportOrder"
            ></date-range-picker>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="3" md="3">
            <v-select
              v-if="statusesWebshopOrder"
              v-model="status"
              :items="statusesWebshopOrder"
              :label="$t('FORMS.status')"
              item-text="$t('ORDER_STATUSES.'+value)"
              item-value="key"
            >
              <template slot="selection" slot-scope="slotProps">
                {{ $t("ORDER_STATUSES." + slotProps.item.key) }}
              </template>
              <template v-slot:item="slotProps">
                <span class="navi-text">{{
                  $t("ORDER_STATUSES." + slotProps.item.key)
                }}</span>
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" sm="3" md="3">
            <v-text-field
              v-model="search"
              append-icon="search"
              :label="$t('FORMS.search')"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="filteredOrderCollection"
          :footer-props="{
            'items-per-page-options': [50, 75, 100],
          }"
          :items-per-page="50"
          :search="search"
          :sort-by="['id']"
          sort-desc="true"
          :loading="loadingTable"
          @click:row="handleClickItem"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <OrderModalForm
                :modalData="modalData"
                :permissions="permissions"
                :statuses="statuses"
                :endPoint="endPoint"
                :orderCollection="orderCollection"
                @closeModalForm="handleCloseModalForm"
                @saveModalForm="handleSaveModalForm"
                @handleGLStranfer="handleGLStranfer"
                @new="handleNew"
              ></OrderModalForm>
              <v-spacer></v-spacer>
              <DeleteModalDialog
                :dialogDelete="dialogDelete"
                @closeDelete="handleCloseDelete"
                @deleteItemConfirm="handleDeleteItemConfirm"
              >
              </DeleteModalDialog>
            </v-toolbar>
          </template>
          <!-- <template v-slot:item.name="{ item }">
            <span v-if="item.custom_fields">
              {{ item.custom_fields.surname }}
              {{ item.custom_fields.christian_name1 }}
            </span>
          </template>
          <template v-slot:item.date="{ item }">
            <span v-if="item.custom_fields">
              {{ item.custom_fields.ordered_at }}
            </span>
          </template>
          <template v-slot:item.phone="{ item }">
            <span v-if="item.custom_fields">
              {{ item.custom_fields.phone }}
            </span>
          </template>
          <template v-slot:item.email="{ item }">
            <span v-if="item.custom_fields">
              {{ item.custom_fields.email }}
            </span>
          </template>
          <template v-slot:item.amount="{ item }">
            <span v-if="item.custom_fields">
              {{ item.custom_fields.amount }} {{ item.custom_fields.currency }}
            </span>
          </template>
          <template v-slot:item.coupon="{ item }">
            <span v-if="item.custom_fields && item.custom_fields.coupon">
              {{ item.custom_fields.coupon.id }}
            </span>
          </template> -->
          <template v-slot:item.actions="{ item }">
            <v-icon
              color="primary"
              v-if="permissionCan('delete')"
              small
              @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
            <v-icon
              v-if="
                item.status == 5 &&
                item.custom_fields &&
                item.custom_fields.shipping_type === 'delivery'
              "
              color="secondary"
              small
              @click.stop="handleGLStranfer(item)"
              class="ml-2"
            >
              mdi-truck-fast
            </v-icon>
          </template>
        </v-data-table>
        <!-- <pre>
          {{ filteredOrderCollection }}
        </pre> -->
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import DateRangePicker from "vue2-daterange-picker";

import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

import ApiService from "@/core/services/api.service";

import { mapGetters, mapActions } from "vuex";

import SiteService from "@/core/services/site.service.js";

import { listModelsMixins } from "@/view/mixins/listModelsMixins.js";

import OrderModalForm, { initialFormData } from "./OrderModalForm";

import DeleteModalDialog from "@/view/components/DeleteModalDialog";

import Swal from "sweetalert2";

export const PERMISSION_TO = "webshop.order.";

export default {
  name: "Orders",
  components: { OrderModalForm, DeleteModalDialog, DateRangePicker },
  mixins: [listModelsMixins],
  data() {
    return {
      cardTitle: this.$t("WEBSHOP.orders"),
      search: "",
      routePath: "/webShop/orders/",
      permissionTo: PERMISSION_TO,
      permissions: JSON.parse(localStorage.permissions),
      headers: [
        {
          text: "ID",
          value: "order_number",
          // width: "100px",
        },
        {
          text: this.$t("FORM_INPUT_NAMES.date"),
          value: "ordered_at",
        },
        {
          text: this.$t("TABLE.name"),
          value: "name",
        },
        {
          text: this.$t("TABLE.phone"),
          value: "phone",
        },
        {
          text: "Email",
          value: "email",
        },
        {
          text: this.$t("TABLE.coupon"),
          value: "coupon",
          sortable: false,
        },
        // { text: "Status", value: "status" },
        {
          text: this.$t("TABLE.amount"),
          value: "amount",
        },
        {
          text: this.$t("TABLE.actions"),
          value: "actions",
          sortable: false,
          width: "100px",
        },
      ],

      modalData: {
        dialog: false,
        editedId: null,
      },
      editedItem: Object.assign({}, initialFormData()),

      dialogDelete: false,
      dateRange: {
        startDate: new Date(),
        endDate: new Date(),
      },

      localeData: {
        direction: "ltr",
        format: "yyyy-mm-dd",
        separator: " - ",
        applyLabel: "Export CSV",
        cancelLabel: "Mégse",
        weekLabel: "W",
        customRangeLabel: "Custom Range",
        daysOfWeek: ["Vas", "Hét", "Ked", "Sze", "Csü", "Pén", "Szo"],
        monthNames: [
          "Jan",
          "Feb",
          "Már",
          "Ápr",
          "Máj",
          "Jun",
          "Jul",
          "Aug",
          "Sze",
          "Okt",
          "Nov",
          "Dec",
        ],
        firstDay: 1,
      },
      status: 1,
      // filteredOrderCollection: [],
    };
  },

  computed: {
    ...mapGetters(["orderCollection", "statuses", "productCollection"]),

    endPoint() {
      // return "user/";
      return "webshop/admin/" + SiteService.getActiveSiteId() + "/order/";
      // api.loc/api/webshop/admin/1/order/
    },
    filteredOrderCollection() {
      let status = !this.status ? null : this.status;
      return this.orderCollection
        .filter((item) => item.status == status || status == "all")
        .map((item) => {
          item.name =
            item.custom_fields.surname +
            " " +
            item.custom_fields.christian_name1;
          item.ordered_at = item.custom_fields.ordered_at;
          item.phone = item.custom_fields.phone;
          item.email = item.custom_fields.email;
          item.amount =
            item.custom_fields.amount + " " + item.custom_fields.currency;
          item.coupon =
            item.custom_fields && item.custom_fields.coupon
              ? item.custom_fields.coupon.id
              : "";

          return item;
        });
    },

    statusesWebshopOrder() {
      if (
        !this.statuses ||
        !this.statuses.webshop ||
        !this.statuses.webshop.order
      )
        return [];

      return [
        {
          key: "all",
          value: "all",
        },
        ...this.statuses.webshop.order,
      ];
    },
  },

  watch: {
    dialogDelete(val) {
      val || this.handleCloseDelete();
    },
    "$route.params.id": {
      handler: function (id) {
        if (id) {
          this.editItem(id);
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    ...mapActions(["fetchOrder", "fetchProduct"]),

    fetchModel() {
      this.fetchProduct();
      return this.fetchOrder();
    },

    dateFormat(classes, date) {
      if (!classes.disabled) {
        const today = new Date();
        const tomorrow = new Date(today);
        tomorrow.setDate(tomorrow.getDate() + 1);
        classes.disabled = date.getTime() > tomorrow;
      }
      return classes;
    },

    getPackageQuantity(products) {
      let packages = {};
      products.map((item) => {
        item.product = {
          ...this.$store.getters.getProductByID(item.product_id),
        };

        if (!packages[item.product["shipping_quantity"]]) {
          packages[item.product["shipping_quantity"]] = 0;
        }
        packages[item.product["shipping_quantity"]] += +item.quantity;
        return item;
      });

      let quantity = 0;

      Object.entries(packages).forEach((item) => {
        quantity += item[1] / item[0];
      });

      return quantity;
    },

    formatOrderToCSV(order) {
      let custom_fields = order.custom_fields;
      let formatted = [];
      let d = new Date(order.created_at);
      formatted.push(d.toLocaleString("hu-HU", { timeZone: "UTC" }));
      formatted.push(order.order_number);

      formatted.push(
        custom_fields.surname + " " + custom_fields.christian_name1
      );
      if (custom_fields.shipping_address) {
        formatted.push(custom_fields.shipping_address.zip || "");
        formatted.push(custom_fields.shipping_address.city || "");
        formatted.push(custom_fields.shipping_address.address || "");
      } else {
        formatted.push("");
        formatted.push("");
        formatted.push("");
      }

      formatted.push(custom_fields.email || "");
      formatted.push(custom_fields.phone || "");
      formatted.push(custom_fields.amount || "");

      if (custom_fields.coupon) {
        formatted.push(custom_fields.coupon.id || "");
        formatted.push(custom_fields.coupon.discount_amount || "");
      } else {
        formatted.push("");
        formatted.push("");
      }

      if (
        custom_fields.shipping_type == "delivery" ||
        custom_fields.shipping_type == "post"
      ) {
        formatted.push(custom_fields.shipping_price || "");
      } else {
        formatted.push("");
      }

      if (
        custom_fields.payment_type == "borgun" &&
        custom_fields.payment_data
      ) {
        formatted.push(custom_fields.payment_data.card_number || "");
      } else {
        formatted.push("");
      }

      // formatted.push(this.getPackageQuantity(custom_fields.products));
      let status = +order.status;
      formatted.push(this.$t("ORDER_STATUSES." + status));

      return formatted;
    },

    exportOrdersToCsv(filename, orders) {
      let rows = [];
      let header = [
        "Dátum",
        "Vásárlás azonosítója",
        "Vásárló neve",
        "irányitószám",
        "Város",
        "Cím",
        "Email",
        "Telefon",
        "Tranzakció értéke",
        "Kedvezmény neve",
        "Mértéke",
        "Szállítási költség",
        "Bankkártya",
        "Rendelés státusza",
      ];

      rows = [header, ...rows];
      let formatted = null;
      orders.forEach((element) => {
        formatted = this.formatOrderToCSV(element[1]);
        if (formatted) {
          rows = [...rows, formatted];
        }
      });

      var processRow = function (row) {
        var finalVal = "";
        for (var j = 0; j < row.length; j++) {
          var innerValue = row[j] === null ? "" : row[j].toString();
          if (row[j] instanceof Date) {
            innerValue = row[j].toLocaleString();
          }
          var result = innerValue.replace(/"/g, '""');
          if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"';
          if (j > 0) finalVal += ";";
          finalVal += result;
        }
        return finalVal + "\n";
      };

      var csvFile = "";
      for (var i = 0; i < rows.length; i++) {
        csvFile += processRow(rows[i]);
      }

      var blob = new Blob([csvFile], { type: "text/csv;charset=win1250;" });
      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, filename);
      } else {
        var link = document.createElement("a");
        if (link.download !== undefined) {
          var url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", filename);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },

    exportOrder() {
      ApiService.post("1/order/getAllOrders", this.dateRange)
        .then(({ data }) => {
          let filename =
            "Rendelesek_" +
            this.dateRange.startDate
              .toLocaleDateString("hu-HU")
              .replaceAll(". ", "-")
              .replaceAll(".", "_") +
            this.dateRange.endDate
              .toLocaleDateString("hu-HU")
              .replaceAll(". ", "-") +
            "csv";

          this.exportOrdersToCsv(filename, Object.entries(data["orders"]));
        })
        .catch((error) => {
          console.log("Error!: ", error);
        })
        .finally(() => {
          this.loader = false;
        });
    },
    handleGLStranfer(item) {
      ApiService.get(
        "webshop/admin/" +
          SiteService.getActiveSiteId() +
          `/order/${item.id}/calculateParcelNum`
      )
        .then(({ data }) => {
          Swal.fire({
            // title: "Biztos elküldöd a rendelést a GLS-nek?",
            title: "Nyomtatandó címke darabszáma:",
            // html:
            //   `<input type="number" name="value" style="border:1px solid grey; width:30%;padding:3px" min="1" value="${data}" id="szamInput">` +
            //   '<p style="margin-top: 10px;">Amennyiben szeretnéd felülírni, kérlek módosítsd a mennyiséget.</p>',
            input: "number",
            inputAttributes: {
              min: 1,
            },
            inputValue: data,
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: "Elküld a GLS-nek",
            denyButtonText: `Bezár`,
            cancelButtonText: `Bezár`,
          }).then((result) => {
            if (result.isConfirmed) {
              ApiService.post(
                "webshop/admin/" +
                  SiteService.getActiveSiteId() +
                  `/order/${item.id}/ship`,
                { parcel_num: result.value }
              )
                .then(({ data }) => {
                  if (data.id) {
                    Swal.fire("Elküldve!", "", "success").then(() => {
                      this.handleCloseModalForm();
                    });
                  } else {
                    Swal.fire("Nincs elküldve", "", "info");
                  }
                })
                .catch(() => {
                  Swal.fire("Nincs elküldve", "", "info");
                })
                .finally(() => {
                  this.fetchModel();
                  this.loader = false;
                });
            }
          });
        })
        .catch(() => {
          Swal.fire(
            "Pillanatnyi hiba a kapcsolatban, kérlek próbáld újra!",
            "",
            "info"
          );
        })
        .finally(() => {
          this.fetchModel();
          this.loader = false;
        });
    },
  },

  mounted() {
    this.loadingTable = true;
    this.fetchModel().then(() => {
      this.loadingTable = false;
    });
  },
};
</script>
